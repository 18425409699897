import React, { Suspense, useContext, useEffect } from "react";

import Header from "./header";
import Footer from "./footer";

import { Routes, Route, useNavigate  } from "react-router-dom";
import TopNavbar from "./topNavbar";
import { UserContext } from "../context/theme";
import NotFound from "../components/notFound/notFound";
import { ToastContainer } from "react-toastify";
import authAxios from "../services/authAxios";
import ROOT_URL from "../services/api-url";
import { reactLocalStorage } from "reactjs-localstorage";
import Loader from "../components/loader/loader";

const Home = React.lazy(() => import("../pages/home"));
const AdminList = React.lazy(() => import("../pages/admin/list"));
const AdminCreate = React.lazy(() => import("../pages/admin/add"));
const AdminEdit = React.lazy(() => import("../pages/admin/edit"));
const ChangesPassword = React.lazy(() =>
  import("../pages/settings/changePassword")
);
const Settings = React.lazy(() => import("../pages/settings/settings"));
const ChangesPasswordAdmin = React.lazy(() =>
  import("../pages/admin/adminChangePassword")
);

const ContinentList = React.lazy(() => import("../pages/continent/list"));
const ContinentAdd = React.lazy(() => import("../pages/continent/add"));
const ContinentEdit = React.lazy(() => import("../pages/continent/edit"));

const CountryList = React.lazy(() => import("../pages/country/list"));
const CountryAdd = React.lazy(() => import("../pages/country/add"));
const CountryEdit = React.lazy(() => import("../pages/country/edit"));
const CountrySeoEdit = React.lazy(() => import("../pages/country/edit-seo"));

const StateList = React.lazy(() => import("../pages/state/list"));
const StateAdd = React.lazy(() => import("../pages/state/add"));
const StateEdit = React.lazy(() => import("../pages/state/edit"));

const CityList = React.lazy(() => import("../pages/city/list"));
const CityAdd = React.lazy(() => import("../pages/city/add"));
const CityEdit = React.lazy(() => import("../pages/city/edit"));


const CategoryList = React.lazy(() => import("../pages/category/list"));
const CategoryAdd = React.lazy(() => import("../pages/category/add"));
const CategoryEdit = React.lazy(() => import("../pages/category/edit"));


const AmenitiesList = React.lazy(() => import("../pages/amenities/list"));
const AmenitiesAdd = React.lazy(() => import("../pages/amenities/add"));
const AmenitiesEdit = React.lazy(() => import("../pages/amenities/edit"));
const NatureOfTripList = React.lazy(() => import("../pages/natureOfTrip/list"));
const NatureOfTripAdd = React.lazy(() => import("../pages/natureOfTrip/add"));
const NatureOfTripEdit = React.lazy(() => import("../pages/natureOfTrip/edit"));

const BlogCategoryList = React.lazy(() => import("../pages/blogCategory/list"));
const BlogCategoryAdd = React.lazy(() => import("../pages/blogCategory/add"));
const BlogCategoryEdit = React.lazy(() => import("../pages/blogCategory/edit"));

const BlogSubCategoryList = React.lazy(() => import("../pages/blogSubCategory/list"));
const BlogSubCategoryAdd = React.lazy(() => import("../pages/blogSubCategory/add"));
const BlogSubCategoryEdit = React.lazy(() => import("../pages/blogSubCategory/edit"));

const BlogAuthorAdd = React.lazy(() => import("../pages/blogAuthor/add"));
const BlogAuthorList = React.lazy(() => import("../pages/blogAuthor/list"));
const BlogAuthorEdit = React.lazy(() => import("../pages/blogAuthor/edit"));

const BlogCreate = React.lazy(() => import("../pages/blog/add"));
const BlogList = React.lazy(() => import("../pages/blog/list"));
const BlogEdit = React.lazy(() => import("../pages/blog/edit"));
const BlogSeoEdit = React.lazy(() => import("../pages/blog/edit-seo"));

const VendorOnboardingList = React.lazy(() => import("../pages/vendorOnboarding/list"));
const VendorOnboardingAdd = React.lazy(() => import("../pages/vendorOnboarding/add"));
const VendorOnboardingEdit = React.lazy(() => import("../pages/vendorOnboarding/edit"));

const ListingList = React.lazy(() => import("../pages/listing/list"));
const ListingAdd = React.lazy(() => import("../pages/listing/add"));
const ListingEdit = React.lazy(() => import("../pages/listing/edit"));
const SeoListingEdit = React.lazy(() => import("../pages/listing/edit-seo"));
const ListingInquiryList = React.lazy(() => import("../pages/listing/inquiry-list"));
const ListingInquiryView = React.lazy(() => import("../pages/listing/inquiry-view"));


const TestimonialList = React.lazy(() => import("../pages/testimonial/list"));
const TestimonialAdd = React.lazy(() => import("../pages/testimonial/add"));
const TestimonialEdit = React.lazy(() => import("../pages/testimonial/edit"));


const SubCategoryList = React.lazy(() => import("../pages/subCategory/list"));
const SubCategoryAdd = React.lazy(() => import("../pages/subCategory/add"));
const SubCategoryEdit = React.lazy(() => import("../pages/subCategory/edit"));
const ExamList = React.lazy(() => import("../pages/exam/list"));
const ExamAdd = React.lazy(() => import("../pages/exam/add"));
const ExamEdit = React.lazy(() => import("../pages/exam/edit"));
const QuestionList = React.lazy(() => import("../pages/questions/list"));
const QuestionAdd = React.lazy(() => import("../pages/questions/add"));
const QuestionEdit = React.lazy(() => import("../pages/questions/edit"));
const TagsList = React.lazy(() => import("../pages/tags/list"));
const TagsAdd = React.lazy(() => import("../pages/tags/add"));
const TagsEdit = React.lazy(() => import("../pages/tags/edit"));
const UserList = React.lazy(() => import("../pages/user/list"));
const UserView = React.lazy(() => import("../pages/user/view"));
const PackageCreate = React.lazy(() => import("../pages/package/add"));
const PackageList = React.lazy(() => import("../pages/package/list"));
const PackageEdit = React.lazy(() => import("../pages/package/edit"));
const CaseStudyCreate = React.lazy(() => import("../pages/caseStudy/add"));
const CaseStudyList = React.lazy(() => import("../pages/caseStudy/list"));
const CaseStudyEdit = React.lazy(() => import("../pages/caseStudy/edit"));
const TestCreate = React.lazy(() => import("../pages/test/add"));
const TestList = React.lazy(() => import("../pages/test/list"));
const TestEdit = React.lazy(() => import("../pages/test/edit"));

const CouponList = React.lazy(() => import("../pages/coupons/list"));
const CouponCreate = React.lazy(() => import("../pages/coupons/add"));
const CouponEdit = React.lazy(() => import("../pages/coupons/edit"));

const HelpDeskList = React.lazy(() => import("../pages/helpDesk/list"));
const HelpDeskChat = React.lazy(() => import("../pages/helpDesk/chat"));
const HomePageContent = React.lazy(() => import("../pages/information/home"));
const ContactUs = React.lazy(() => import("../pages/contact/list"));
const Newsletter = React.lazy(() => import("../pages/newsletter/list"));
const TermsCondition = React.lazy(() => import("../pages/information/terms"));
const PrivacyPolicy = React.lazy(() => import("../pages/information/privacy"));
const AboutUs = React.lazy(() => import("../pages/information/about"));
const Comments = React.lazy(() => import("../pages/comments/list"));
const CommentsView = React.lazy(() => import("../pages/comments/moderate"));
const FaqsList = React.lazy(() => import("../pages/faqs/list"));
const FaqsAdd = React.lazy(() => import("../pages/faqs/add"));
const FaqsEdit = React.lazy(() => import("../pages/faqs/edit"));
const UserFooter = React.lazy(() => import("../pages/information/footer"));
const Review = React.lazy(() => import("../pages/reviews/list"));
const ReviewView = React.lazy(() => import("../pages/reviews/moderate"));
const QsnReport = React.lazy(() => import("../pages/qsnReport/list"));
const OrderList = React.lazy(() => import("../pages/orders/list"));
const OrdersView = React.lazy(() => import("../pages/orders/view"));
function Layout() {
  const context = useContext(UserContext);
  const { userInfo, setAuth } = useContext(UserContext);
  const navigate = useNavigate();
  function refreshtoken() {
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/admins/token/refresh`,
    })
      .then((res) => {
        reactLocalStorage.set("token", res.data.token);
      })
      .catch((error) => {
        // reactLocalStorage.remove("token");
      });
  }
  useEffect(() => {
    let token = reactLocalStorage.get("token");
    const intrVal = setInterval(() => {
      refreshtoken();
    }, 3600000);
    if (!token) {
      clearInterval(intrVal);
    }
  }, []);

  const logoutHandler = () => {
    console.clear();
    localStorage.removeItem("token");
    reactLocalStorage.clear();
    setAuth(false);
    navigate("./");
  };

  useEffect(() => {
    if (setAuth === true) {
      authAxios({
        method: "POST",
        url: `${ROOT_URL}/change_password`,
      })
        .then((res) => {
        })
        .catch((error) => {
          logoutHandler();
        });
    }
  })

  return (
    <div
      className={`page-wrapper 
      ${context.Theme} 
      ${context.ThemeColor} 
      ${context.MiniSidebar ? "mini-sidebar" : ""}      
    `}
    >
      <ToastContainer />
      <Header />
      <div className="all-content-wrapper">
        <TopNavbar />
        <Loader />
        <Suspense fallback={"loading"}>
          {/* MODULE_MANAGER */}

          {userInfo.role == "MODULE_MANAGER" ? (
            <Routes>
              <Route path="/exam" element={<ExamList />} />
              <Route path="/create-exam" element={<ExamAdd />} />
              <Route path="/exam/:id" element={<ExamEdit />} />
              <Route path="/question" element={<QuestionList />} />
              <Route path="/create-question" element={<QuestionAdd />} />
              <Route
                path="/create-question/:case_id/:caseId"
                element={<QuestionAdd />}
              />
              <Route path="/question/:id" element={<QuestionEdit />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/create-package" element={<PackageCreate />} />
              <Route path="/packages" element={<PackageList />} />
              <Route path="/edit-package/:id" element={<PackageEdit />} />
              <Route path="/create-test/:examId" element={<TestCreate />} />
              <Route path="/test" element={<TestList />} />
              <Route path="/edit-test/:id" element={<TestEdit />} />
            </Routes>
          ) : userInfo.role == "ACCOUNT_MANAGER" ? ( //Account_Manager
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
            </Routes>
          ) : userInfo.role === "CONTENT_MANAGER" ? ( //Content_Manager
            <Routes>
              <Route path="/category" element={<CategoryList />} />
              <Route path="/add-category" element={<CategoryAdd />} />
              <Route path="/edit-category/:id" element={<CategoryEdit />} />
            </Routes>
          ) : (
            <Routes>
              {" "}
              {/* Admin */}
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/admin" element={<AdminList />} />
              <Route path="/continent" element={<ContinentList />} />
              <Route path="/create-continent" element={<ContinentAdd />} />
              <Route path="/edit-continent/:id" element={<ContinentEdit />} />
              <Route path="/country" element={<CountryList />} />
              <Route path="/create-country" element={<CountryAdd />} />
              <Route path="/edit-country/:id" element={<CountryEdit />} />
              <Route path="/update-seo/:id" element={<CountrySeoEdit />} />

              <Route path="/state" element={<StateList />} />
              <Route path="/create-state" element={<StateAdd />} />
              <Route path="/edit-state/:id" element={<StateEdit />} />
              <Route path="/city" element={<CityList />} />
              <Route path="/create-city" element={<CityAdd />} />
              <Route path="/edit-city/:id" element={<CityEdit />} />

              <Route path="/category" element={<CategoryList />} />
              <Route path="/add-category" element={<CategoryAdd />} />
              <Route path="/edit-category/:id" element={<CategoryEdit />} />

              <Route path="/amenities" element={<AmenitiesList />} />
              <Route path="/create-amenities" element={<AmenitiesAdd />} />
              <Route path="/edit-amenities/:id" element={<AmenitiesEdit />} />

              <Route path="/nature-of-trip" element={<NatureOfTripList />} />
              <Route path="/create-nature-of-trip" element={<NatureOfTripAdd />} />
              <Route path="/edit-nature-of-trip/:id" element={<NatureOfTripEdit />} />

              <Route path="/create-blog-category" element={<BlogCategoryAdd />} />
              <Route path="/list-blog-category" element={<BlogCategoryList />} />
              <Route path="/edit-blog-category/:id" element={<BlogCategoryEdit />} />

              <Route path="/create-blog-sub-category" element={<BlogSubCategoryAdd />} />
              <Route path="/list-blog-sub-category" element={<BlogSubCategoryList />} />
              <Route path="/edit-blog-sub-category/:id" element={<BlogSubCategoryEdit />} />

              <Route path="/create-blog-author" element={<BlogAuthorAdd />} />
              <Route path="/author-blog" element={<BlogAuthorList />} />
              <Route path="/edit-author-blog/:id" element={<BlogAuthorEdit />} />

              <Route path="/create-blog" element={<BlogCreate />} />
              <Route path="/blog" element={<BlogList />} />
              <Route path="/blog/:id" element={<BlogEdit />} />
              <Route path="/blog-update-seo/:id" element={<BlogSeoEdit />} />
              <Route path="/supplier" element={<VendorOnboardingList />} />
              <Route path="/create-supplier" element={<VendorOnboardingAdd />} />
              <Route path="/edit-supplier/:id" element={<VendorOnboardingEdit />} />

              <Route path="/listing" element={<ListingList />} />
              <Route path="/create-listing" element={<ListingAdd />} />
              <Route path="/edit-listing/:id" element={<ListingEdit />} />
              <Route path="/update-listing-seo/:id" element={<SeoListingEdit />} />
              
              <Route path="/listing-inquiry" element={<ListingInquiryList />} />
              <Route path="/inquiry-view/:id" element={<ListingInquiryView />} />


              <Route path="/testimonial" element={<TestimonialList />} />
              <Route path="/add-testimonial" element={<TestimonialAdd />} />
              <Route path="/edit-testimonial/:id" element={<TestimonialEdit />} />
            
              <Route path="/user-view/:id" element={<UserView />} />
              <Route path="/create-admin" element={<AdminCreate />} />
              <Route path="/edit-admin/:id" element={<AdminEdit />} />
              <Route path="/change-password" element={<ChangesPassword />} />
              <Route path="/settings" element={<Settings />} />
              <Route
                path="/change-password/:id"
                element={<ChangesPasswordAdmin />}
              />
              
              <Route path="/sub-category" element={<SubCategoryList />} />
              <Route path="/add-sub-category" element={<SubCategoryAdd />} />
              <Route
                path="/edit-sub-category/:id"
                element={<SubCategoryEdit />}
              />
              <Route path="/exam" element={<ExamList />} />
              <Route path="/create-exam" element={<ExamAdd />} />
              <Route path="/exam/:id" element={<ExamEdit />} />
              <Route path="/question" element={<QuestionList />} />
              <Route path="/create-question" element={<QuestionAdd />} />
              <Route
                path="/create-question/:case_id/:caseId"
                element={<QuestionAdd />}
              />
              <Route path="/question/:id" element={<QuestionEdit />} />
              <Route path="/tags" element={<TagsList />} />
              <Route path="/create-tag" element={<TagsAdd />} />
              <Route path="/tags/:id" element={<TagsEdit />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/create-package" element={<PackageCreate />} />
              <Route path="/packages" element={<PackageList />} />
              <Route path="/edit-package/:id" element={<PackageEdit />} />
              <Route path="/create-case-study" element={<CaseStudyCreate />} />
              <Route path="/case-study" element={<CaseStudyList />} />
              <Route path="/edit-case-study/:id" element={<CaseStudyEdit />} />
              <Route path="/create-test/:examId" element={<TestCreate />} />
              <Route path="/test" element={<TestList />} />
              <Route path="/edit-test/:id" element={<TestEdit />} />
              <Route path="/create-blog" element={<BlogCreate />} />
              <Route path="/coupon" element={<CouponList />} />
              <Route path="/create-coupon" element={<CouponCreate />} />
              <Route path="/edit-coupon/:id" element={<CouponEdit />} />
              
              <Route path="/helpdesk" element={<HelpDeskList />} />
              <Route path="/helpdesk/chat/:id" element={<HelpDeskChat />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route path="/newsletter" element={<Newsletter />} />
              <Route path="/privacy" element={<PrivacyPolicy />} />
              <Route path="/terms" element={<TermsCondition />} />
              <Route path="/about" element={<AboutUs />} />
              <Route path="/homepage" element={<HomePageContent />} />
              <Route path="/create-faqs" element={<FaqsAdd />} />
              <Route path="/faqs/" element={<FaqsList />} />
              <Route path="/faqs/:id" element={<FaqsEdit />} />
              <Route path="/comments" element={<Comments />} />
              <Route path="/comment-view/:id" element={<CommentsView />} />
              <Route path="/footer" element={<UserFooter />} />
              <Route path="/reviews" element={<Review />} />
              <Route path="/review-view/:id" element={<ReviewView />} />
              <Route path="/orders" element={<OrderList />} />
              <Route path="/order-view/:id" element={<OrdersView />} />
              <Route path="/report-questions" element={<QsnReport />} />
            </Routes>
          )}
        </Suspense>
      </div>
      <Footer />
    </div>
  );
}

export default Layout;
