import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../context/theme";
import { IMAGE_URL } from "../services/api-url";
import PageBreadCrumbs, { PageName } from "./headerBreadcrumbs";

const Notification = [
  {
    Avatar: "assets/images/avatar-3.jpg",
    Name: "John Doe",
    Description: "Lorem ipsum dolor sit amet,consectetuer elit.",
    NotificDate: "02 Jan 2022",
  },
  {
    Avatar: "assets/images/avatar-3.jpg",
    Name: "Tailor",
    Description: "Lorem ipsum dolor sit amet,consectetuer elit.",
    NotificDate: "03 Jan 2022",
  },
  {
    Avatar: "assets/images/avatar-3.jpg",
    Name: "Cherrry Warner",
    Description: "Lorem ipsum dolor sit amet,consectetuer elit.",
    NotificDate: "4 Jan 2022",
  },
];

const Setting = [
  {
    title: "Settings",
    icon: "icon-settings",
    to: "/settings",
  },
  {
    title: "Change Password",
    icon: "icon-key",
    to: "/change-password",
  },
  {
    title: "Logout",
    icon: "icon-log-out",
    to: "/",
  },
];

function TopNavbar() {
  const { userInfo, MiniSidebar, setMiniSidebar, setAuth } =
    useContext(UserContext);

  const [isActive, setActive] = useState("false");

  const ToggleClass = () => {
    setActive(!isActive);
  };
  function ToggleSidebar() {
    setMiniSidebar(!MiniSidebar);
  }
  const Logout = () => {
    localStorage.removeItem("token");
    setAuth(false);
  };
  return (
    <nav className="navbar header-navbar">
      <div className="navbar-wrapper w-100">
        <div className="navbar-container container-fluid">
          <div className="row w-100 align-items-center">
            <div className="col-2">
              <div className="nav-left mr-auto">
                <div className="menu-hamburger ml-1 fs-5">
                  <span
                    className="icon-menu"
                    onClick={() => ToggleSidebar()}
                  ></span>
                  <span
                    className="icon-x"
                    onClick={() => ToggleSidebar()}
                  ></span>
                  <span className="bredcrumHead">
                    <PageName />
                  </span>
                </div>
                <div className="slideUp_header  ml-1 fs-5">
                  <span className="icon-arrow-up"></span>
                </div>
              </div>
            </div>
            <div className="col-10">
              <ul className="nav-right d-flex align-items-center justify-content-end mb-0">
                {/* Notification */}
                {/* <li className="header-notification pr-5">
                  <div className="dropdown-primary dropdown">
                    <div
                      className="dropdown-toggle position-relative"
                      onClick={ToggleClass}
                      type="button"
                      id="notificaitonBell"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="feather icon-bell"></i>
                      <span className="badge bg-danger rounded-circle position-absolute">
                        5
                      </span>
                    </div>
                    <ul
                      className={
                        isActive
                          ? "dropdown-menu dropdown-menu-end"
                          : "dropdown-menu dropdown-menu-end show"
                      }
                      aria-labelledby="notificaitonBell"
                    >
                      <li className="p-3 d-flex align-items-center justify-content-between">
                        <h6>Notifications</h6>
                        <label className="badge fw-normal bg-warning rounded">
                          New
                        </label>
                      </li>
                      {Notification.map((item, i) => (
                        <li className="px-3 mb-3 py-1" key={i}>
                          <div className="media d-flex align-items-center">
                            <img
                              className="flex-shrink-0 rounded-circle img-fluid"
                              src={item.Avatar}
                              alt="Generic placeholder"
                            />
                            <div className="media-body flex-shrink-1 pl-3">
                              <h5 className="notification-user">{item.Name}</h5>
                              <p className="notification-msg">
                                {item.Description}
                              </p>
                              <span className="notification-time">
                                {item.NotificDate}
                              </span>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </li> */}

                {/* User profile  */}
                <li className="user-profile header-notification">
                  <div className="dropdown-primary dropdown">
                    <div
                      className="dropdown-toggle"
                      role="button"
                      id="dropdownMenuLink"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {userInfo.userImage?.path ? (
                        <img
                        src={`${IMAGE_URL}${userInfo.userImage?.path}`}
                        className="img-radius img-fluid"
                        alt="User-Profile"
                      />
                      ) : (
                      <img
                        src={`/assets/images/user.png`}
                        className="img-radius img-fluid"
                        alt="User-Profile"
                      />
                      )}
                      <span>{userInfo.name}</span>
                      <i className="feather icon-chevron-down"></i>
                    </div>
                    <ul
                      className="show-notification dropdown-menu-end profile-notification dropdown-menu"
                      data-dropdown-in="fadeIn"
                      data-dropdown-out="fadeOut"
                      aria-labelledby="dropdownMenuLink"
                    >
                      {Setting.map((item, i) =>
                        item.title == "Logout" ? (
                          <li key={i}>
                            <a onClick={() => Logout()}>
                              <i className={item.icon}></i> {item.title}
                            </a>
                          </li>
                        ) : (
                          <li key={i}>
                            <Link to={item.to}>
                              <i className={item.icon}></i> {item.title}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default TopNavbar;
