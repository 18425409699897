// let ROOT_URL ='http://staging.alphonic.net.in:6002/api/v1/c'
let ROOT_URL = ''
let IMAGE_URL = ''


/* if(window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1'){
    console.log(1);
    // ROOT_URL ='https://jsonplaceholder.typicode.com'
}else{
    // TODO: Server API URL
    // ROOT_URL ='https://jsonplaceholder.typicode.com'
    console.log(2);
} */
console.log(process.env.REACT_APP_SERVER)
if (process.env.REACT_APP_SERVER) {
    ROOT_URL = 'https://api.triptoocean.com/api/v1/a'
    IMAGE_URL = 'https://d2zsm28q4aw2dx.cloudfront.net/'
} else {
    ROOT_URL = 'http://localhost:6400/api/v1/a'
    IMAGE_URL = 'https://d2zsm28q4aw2dx.cloudfront.net/'
}
export { IMAGE_URL };
export default ROOT_URL;