
import authAxios from "../services/authAxios";
import UnauthAxios from "../services/unauthAxios";
import ROOT_URL from "./api-url";

const apiFunc = {
  refreshToken: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/users/token/refresh`,
    }).catch((err) => {
      console.log(err);
    }),
  postUpload: (data) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/media/upload`,
      data: data,
    }).catch((err) => {
      console.log(err);
    }),
  getImage: (id) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/media/download-file/${id}`,
    }).catch((err) => {
      console.log(err);
    }),
  getContinent: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/continent/getAllContinent`,
    }).catch((err) => {
      console.log(err);
    }),
    
  getCountry: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/country/getAllCountry`,
    }).catch((err) => {
      console.log(err);
    }),
    getCountryById: (id) =>
    
    authAxios({
      
      method: "GET",
      url: `${ROOT_URL}/country/getCountryByContinentId/${id}`,
      
    } ).catch((err) => {
      console.log(err);
    }),  
  getState: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/state/getAllState`,
    }).catch((err) => {
      console.log(err);
    }),
    getStateById: (id) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/state/getStateByCountryId/${id}`,
    }).catch((err) => {
      console.log(err);
    }),  
 
    getBlogCategory: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/blog-category/getAllCategories`,
    }).catch((err) => {
      console.log(err);
    }),

    getBlogSubCatById:(id)=>
    authAxios({
      method:"Get",
      url: `${ROOT_URL}/blog-sub-category/getSubCategoryByCategoryId/${id}`,
    }).catch((err) => {
      console.log(err);
    }),
    
    getBlogAuthor: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/blog-author/getAllAuthor`,
    }).catch((err) => {
      console.log(err);
    }),
    
    getBlogSubCategory: ()=>
    authAxios({
      method: "GET",
      url:`${ROOT_URL}/blog-sub-category/getAllBlogSubCat`,
    }).catch((err) => {
      console.log(err);
    }),
    
    getCityById: (id) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/city/getCityByStateId/${id}`,
    }).catch((err) => {
      console.log(err);
    }),
  getNatureOfTrip: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/nature-of-trip/getAllNatureOfTrip`,
    }).catch((err) => {
      console.log(err);
    }),
  getAmenities: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/amenities/getAllAmenities`,
    }).catch((err) => {
      console.log(err);
    }), 
    getCategory: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/category/getAllCategory`,
    }).catch((err) => {
      console.log(err);
    }),
    getVendor: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/vendor-onboarding/getAllVendorOnboarding`,
    }).catch((err) => {
      console.log(err);
    }),
    searchAmenities: (text) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/amenities/search/${text}`,
    }).catch((err) => {
      console.log(err);
    }),
  searchTCategory: (text) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/category/search/${text}`,
    }).catch((err) => {
      console.log(err);
    }),
  searchNatureOfTrip: (text) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/nature-of-trip/search/${text}`,
    }).catch((err) => {
      console.log(err);
    }),
//////////////////////////////////////////////////Not Being used/////////////////////////////////////////////////////////
  getSubCategoryById: (id) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/ aaaa/getSubCategoryByCategoryId/${id}`,
    }).catch((err) => {
      console.log(err);
    }),
  getExamsByCategoryId: (id) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/exam/getExamsByCategoryId/${id}`,
    }).catch((err) => {
      console.log(err);
    }),
  getOptionId: () =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/question/generateId`,
    }).catch((err) => {
      console.log(err);
    }),
  searchQSN: (text) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/question/search`,
      data: text
    }).catch((err) => {
      console.log(err);
    }),
  searchTags: (text) =>
    authAxios({
      method: "GET",
      url: `${ROOT_URL}/tags/search/${text}`,
    }).catch((err) => {
      console.log(err);
    }),
  searchExam: (text) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/exam/search`,
      data: text
    }).catch((err) => {
      console.log(err);
    }),

  searchCaseStudy: (text) =>
    authAxios({
      method: "POST",
      url: `${ROOT_URL}/caseStudy/search`,
      data: text
    }).catch((err) => {
      console.log(err);
    }),


};

export default apiFunc;
